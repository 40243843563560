<template>
  <div style="border: 0;">
    <section
      class="pdf-item"
    >
      <div class="testpdf-1-top">
        <p style="margin-left: 15px; margin-top: 15px; padding-bottom: 15px;">
          H.I.C
        </p>
        <p style="padding-left: 6px; font-weight: 800; background-color: rgb(191, 227, 255, 0.5); width: 500px; border-radius: 4px;">
          Contrat de location
        </p>
      </div>
      <!-- <div class="testpdf-1-middle" /> -->
      <div
        class="testpdf-all-middle d-flex flex-column"
      >
        <div
          v-if="rentalData && rentalData.user_id"
          class="d-flex border w-100 p-1"
        >
          <div class="w-50 border-right">
            <h4>Le locataire</h4>
            <h6
              class="pl-2"
            >
              Société : {{ rentalData.user_id.enterprise }}
            </h6>
            <h6
              class="pl-2"
            >
              Adresse : {{ rentalData.client_address }}
            </h6>
            <h6
              class="pl-2"
            >
              CP & Ville : {{ rentalData.client_postcode }} {{ rentalData.client_city }}
            </h6>
            <h6
              class="pl-2"
            >
              Utilisateur : {{ rentalData.user_id.username }}
            </h6>
            <h6
              class="pl-2"
            >
              Telephone : {{ rentalData.user_id.phoneNumber }}
            </h6>
          </div>
          <div class="w-50 pl-1">
            <h4>Le propriétaire</h4>
            <h6
              class="pl-2"
            >
              Société : Normenjeu
            </h6>
            <h6
              class="pl-2"
            >
              Nom : {{ rentalData.personInCharge }}
            </h6>
            <h6
              class="pl-2"
            >
              Telephone : 04 28 87 00 32
            </h6>
          </div>
        </div>
        <div
          v-if="rentalData && rentalData.device"
          class="d-flex border w-100 p-1"
        >
          <div class="w-50 border-right">
            <h4>Le matériel</h4>
            <h6
              class="pl-2"
            >
              N°de serie : {{ rentalData.device.name }}
            </h6>
            <h6
              class="pl-2"
            >
              Modèle : Tête HIC
            </h6>
          </div>
          <div class="w-50 pl-1">
            <h4>La location</h4>
            <h6
              class="pl-2"
            >
              Du {{ rentalData.startDate | displayDate }} au {{ rentalData.endDate | displayDate }}
            </h6>
            <h6
              class="pl-2"
            >
              Prix : {{ rentalData.price }}
            </h6>
            <h6
              class="pl-2"
            >
              Assistance: Oui // Assurance: Non
            </h6>
          </div>
        </div>
        <div
          class="border w-100 p-1 mt-2"
        >
          <div class="d-flex">
            <h4>État du matériel avant location</h4>
            <h6 class="text-right flex-grow-1">
              Compteur de chute : {{ rentalData.fallCounter }}
            </h6>
          </div>
          <div class="d-flex mt-2 pb-2 border-bottom">
            <div class="d-flex flex-column w-50 border-right">
              <img
                src="@/assets/images/divers/head.png"
                alt="logo"
                width="160"
              >
              <small>Notez sur le schéma les accros</small>
            </div>
            <div class="w-50 pl-1 pb-1">
              <h6>État extérieur : </h6>
              <div
                class="bg-light"
                style="width: 100%; height: 90%;"
              >
                {{ rentalData.headState }}
              </div>
            </div>
          </div>
          <div class="d-flex mt-2">
            <div class="d-flex flex-column w-50 border-right">
              <img
                src="@/assets/images/divers/largueur.png"
                alt="logo"
                width="160"
              >
              <small class="mt-2">
                Notez sur le schéma les accros
              </small>
            </div>
            <div class="w-50 pl-1">
              <h6>État extérieur : </h6>
              <div
                class="bg-light"
                style="width: 100%; height: 90%;"
              >
                {{ rentalData.largState }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="testpdf-1-footer">
        <div>
          N°LOC{{ rentalData.id }}
        </div>
        <div
          class="d-flex flex-column align-items-center"
        >
          <small class="mb-0">3 cours d'Herbouville</small>
          <small class="mb-0">69004 LYON</small>
          <small class="mb-0">Tel: 04.28.87.00.32</small>
          <small class="mb-0">Mail: contact@normenjeu.fr</small>
        </div>
        <div>
          <img
            src="@/assets/images/logo/lugdo.png"
            alt="logo"
            width="150"
          >
        </div>
      </div>
    </section>
    <!-- <div class="html2pdf__page-break" /> -->
    <section
      class="pdf-item"
    >
      <div class="testpdf-1-top" />
      <div
        class="testpdf-all-middle d-flex flex-column"
      >
        <div class="flex-column border w-100 p-1">
          <h4>Clause de location</h4>
          <h6>Les clauses de location détaillées au dos s'appliquent</h6>
          <div
            class="d-flex w-100 mt-2"
          >
            <div class="w-50 border-right pr-1">
              <h6>Signature du locataire :</h6>
              <div
                class="bg-light"
                style="width: 100%; height: 100px;"
              />
            </div>
            <div class="w-50 pl-1">
              <h6>Signature du propriétaire :</h6>
              <div
                class="bg-light"
                style="width: 100%; height: 100px;"
              />
            </div>
          </div>
        </div>
        <div class="flex-column border w-100 p-1 mt-1">
          <h4>Retour de location <i>(remplir au retour)</i></h4>
          <div class="d-flex w-50 justify-content-between pr-1">
            <h6>
              Date de retour :
            </h6>
            <div
              class="bg-light ml-2"
              style="width: 190px; height: 20px;"
            />
          </div>
          <div class="d-flex w-50 justify-content-between pr-1">
            <h6>
              Nombre de chute :
            </h6>
            <div
              class="bg-light ml-2"
              style="width: 190px; height: 20px;"
            />
          </div>
          <div class="d-flex w-50 justify-content-between pr-1">
            <h6>
              > 300g :
            </h6>
            <div
              class="bg-light ml-2"
              style="width: 190px; height: 20px;"
            />
          </div>
          <h4>Compte rendu final de la location</h4>
          <div class="d-flex w-100 pb-1">
            <div
              class="bg-light mr-1"
              style="width: 40px; height: 20px;"
            />
            <h6>
              Aucun dommage ni dépassement de chute > 300g
            </h6>
          </div>
          <div class="d-flex w-100 pr-1">
            <div class="d-flex flex-column w-50 pr-1 border-right">
              <div class="d-flex">
                <div
                  class="bg-light mr-1 mb-1"
                  style="width: 40px; height: 20px;"
                />
                <small style="font-size: 0.8em;">
                  Aucun dommage MAIS dépassement de chute > 300g
                </small>
              </div>
              <div class="d-flex">
                <div
                  class="bg-light mr-1 mb-1"
                  style="width: 40px; height: 20px;"
                />
                <small style="font-size: 0.8em;">
                  Facturation maintenance point de contrôle sécurité
                </small>
              </div>
              <div class="d-flex">
                <div
                  class="bg-light mr-1 mb-1"
                  style="width: 40px; height: 20px;"
                />
                <small style="font-size: 0.8em;">
                  Facturation maintenance curative / pièces détériorées
                </small>
              </div>
              <div class="d-flex">
                <div
                  class="bg-light mr-1 mb-1"
                  style="width: 40px; height: 20px;"
                />
                <small style="font-size: 0.8em;">
                  Pas de facturation
                </small>
              </div>
            </div>
            <div class="d-flex flex-column w-50 pl-1">
              <div class="d-flex">
                <div
                  class="bg-light mr-1 mb-1"
                  style="width: 40px; height: 20px;"
                />
                <small style="font-size: 0.8em;">
                  Présence de dommages extérieurs
                </small>
              </div>
              <div class="d-flex">
                <div
                  class="bg-light mr-1 mb-1"
                  style="width: 40px; height: 20px;"
                />
                <small style="font-size: 0.8em;">
                  Facturation maintenance curative / pièces détériorées
                </small>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column mt-2">
            <h6>Indiquez tous les détails des dommages subis : </h6>
            <div
              class="bg-light"
              style="width: 100%; height: 150px;"
            />
          </div>
          <div
            class="d-flex w-100 mt-2"
          >
            <div class="w-50 border-right pr-1">
              <h6>Signature du locataire :</h6>
              <div
                class="bg-light"
                style="width: 100%; height: 100px;"
              />
            </div>
            <div class="w-50 pl-1">
              <h6>Signature du propriétaire :</h6>
              <div
                class="bg-light"
                style="width: 100%; height: 100px;"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="testpdf-1-footer">
        <div>
          N°LOC{{ rentalData.id }}
        </div>
        <div
          class="d-flex flex-column align-items-center"
        >
          <small class="mb-0">3 cours d'Herbouville</small>
          <small class="mb-0">69004 LYON</small>
          <small class="mb-0">Tel: 04.28.87.00.32</small>
          <small class="mb-0">Mail: contact@normenjeu.fr</small>
        </div>
        <div>
          <img
            src="@/assets/images/logo/lugdo.png"
            alt="logo"
            width="150"
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import {
//   BCol, BRow, BCardImg,
// } from 'bootstrap-vue'

export default {
  components: {
    // BCol,
    // BRow,
    // BCardImg,
  },
  props: {
    rentalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      env: process.env.VUE_APP_API_URL,
    }
  },
  methods: {
  },
}
</script>

<style scoped>
/* height for 1 page = 1122px */
.pdf-item {
  width: 100%;
  height: 1122px;
  max-height: 1122px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}
.testpdf-1-top {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 3rem;
  top: 0;
}
.testpdf-1-middle {
  flex-grow: 2;
  width: 100%;
  height: 700px;
  background: center / contain url("../../assets/images/pages/content-img-1.jpg");
  display: flex;
  align-items: flex-start;
}
.testpdf-all-middle {
  width: 100%;
  display: flex;
  height: 700px;
  flex-grow: 2;
  align-items: flex-start;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  font-size: 0.7rem;
}
.testpdf-1-footer {
  display: flex;
  flex-grow: 1;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 10px;
  padding: 10px;
  bottom: 0;
}
</style>
