<template>
  <b-row
    v-if="rentalData"
  >
    <b-col>
      <b-card>
        <b-row>
          <b-col
            cols="21"
            xl="6"
            class="d-flex justify-content-between flex-column mb-2"
          >
            <h2>Processus de location</h2>
            <div class="d-flex justify-content-start">
              <div class="d-flex flex-column ml-1">
                <h4 v-if="rentalData.device">
                  {{ rentalData.device.name }} - du {{ rentalData.startDate | displayDate }} au {{ rentalData.endDate | displayDate }}
                  <b-badge
                    :variant="`light-${resolveStatutVariant(rentalState)}`"
                    pill
                  >
                    {{ rentalState }}
                  </b-badge>
                </h4>
                <div
                  v-if="rentalData.id"
                  class="d-flex flex-wrap"
                >
                  <b-button
                    :to="{ name: 'locations-edit', params: { id: rentalData.id } }"
                    variant="primary"
                  >
                    Modifier
                  </b-button>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row
          class="prog-bar-ref"
          style="height: 100px;"
        >
          <b-col
            cols="12"
            class="prog-bar-1"
          >
            <b-progress
              :max="max"
            >
              <b-progress-bar
                :value="value"
              />
              <b-progress-bar
                :style="$store.state.appConfig.layout.skin === 'dark' ? 'background-color: #283047;' : 'background-color: white;'"
                :value="4 - value"
              />
            </b-progress>
          </b-col>
          <b-col
            cols="12"
            class="prog-bar-2"
          >
            <div class="d-flex justify-content-between w-100 mt-1">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="value >= 1 ? 'gradient-primary' : 'outline-primary'"
                class="btn-icon"
                pill
                @click="changeSelectedStep(1)"
              >
                <feather-icon
                  icon="UsersIcon"
                  size="40"
                />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="value >= 2 ? 'gradient-primary' : 'outline-primary'"
                class="btn-icon"
                pill
                @click="changeSelectedStep(2)"
              >
                <feather-icon
                  icon="FileTextIcon"
                  size="40"
                />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="value >= 3 ? 'gradient-primary' : 'outline-primary'"
                class="btn-icon"
                pill
                @click="changeSelectedStep(3)"
              >
                <feather-icon
                  icon="TruckIcon"
                  size="40"
                />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="value >= 4 ? 'gradient-primary' : 'outline-primary'"
                class="btn-icon"
                pill
                @click="changeSelectedStep(4)"
              >
                <feather-icon
                  icon="CheckCircleIcon"
                  size="40"
                />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        v-if="selectedStep === 1"
      >
        <b-row>
          <b-col cols="12">
            <h2 class="ml-2 mb-2">
              Étape 1
            </h2>
          </b-col>
          <b-col
            v-if="rentalData.device"
            cols="12"
            md="4"
          >
            <table class="mt-2 mt-xl-0 w-100">
              <tr>
                <th>
                  <h4 class="font-weight-bold">
                    Device</h4>
                </th>
                <td class="data-orange">
                  {{ rentalData.device.name }}
                </td>
              </tr>
              <tr>
                <th>
                  <h4 class="font-weight-bold">
                    Début</h4>
                </th>
                <td class="data-orange">
                  {{ rentalData.startDate | displayDate }}
                </td>
              </tr>
              <tr>
                <th>
                  <h4 class="font-weight-bold">
                    Fin</h4>
                </th>
                <td class="data-orange">
                  {{ rentalData.endDate | displayDate }}
                </td>
              </tr>
              <tr>
                <th>
                  <h4 class="font-weight-bold">
                    Géré par</h4>
                </th>
                <td class="data-orange">
                  {{ rentalData.personInCharge }}
                </td>
              </tr>
            </table>
          </b-col>
          <b-col
            v-if="rentalData.device"
            cols="12"
            md="8"
          >
            <table class="mt-2 mt-xl-0 w-100">
              <tr>
                <th>
                  <h4 class="font-weight-bold">
                    Utilisateur</h4>
                </th>
                <td
                  v-if="rentalData.user_id && rentalData.user_id.username"
                  class="data-orange"
                >
                  {{ rentalData.user_id.username }} - {{ rentalData.user_id.enterprise }}
                </td>
              </tr>
              <tr>
                <th>
                  <h4 class="font-weight-bold">
                    Adresse</h4>
                </th>
                <td class="data-orange">
                  {{ rentalData.client_address }}
                  <br>
                  {{ rentalData.client_postcode }} {{ rentalData.client_city }}
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <h4>
              Commentaire
            </h4>
            <p class="data-orange">
              {{ rentalData.comment }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
          >
            <h4 v-if="filesRental">
              Pièces jointes diverses
            </h4>
            <div
              v-for="file, indexFile in filesRental"
              :key="indexFile"
            >
              <b-link
                v-if="file.id !== rentalData.contract_send && file.id !== rentalData.contract_back && file.id !== rentalData.delivery_note && file.id !== rentalData.return_note"
                class="data-orange"
                @click="createAndDownloadBlobFile(file.content, file.originalname, file.extension)"
              >
                {{ file.originalname }}
              </b-link>
              <b-button
                v-if="file.id !== rentalData.contract_send && file.id !== rentalData.contract_back && file.id !== rentalData.delivery_note && file.id !== rentalData.return_note"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                v-b-tooltip.hover
                title="Supprimer"
                variant="flat-danger"
                class="btn-icon rounded-circle"
                @click="removeFile(file.id)"
              >
                <feather-icon
                  icon="DeleteIcon"
                  size="20"
                />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        v-if="selectedStep === 2"
      >
        <b-row>
          <b-col cols="12">
            <h2 class="ml-2">
              Étape 2 - Contrat
            </h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="6"
          >
            <h4 class="font-weight-bold">
              Prix
              <span
                v-if="rentalData.price"
                class="data-orange"
              >
                {{ rentalData.price }}
              </span>
            </h4>
            <h4 class="font-weight-bold">
              Compteur de chute avant location :
              <span
                v-if="rentalData.fallCounter"
                class="data-orange"
              >
                {{ rentalData.fallCounter }}
              </span>
            </h4>
          </b-col>
          <b-col
            sm="6"
          >
            <h4 class="font-weight-bold">
              Assurance
              <span class="data-orange">{{ rentalData.assurance ? 'Oui' : 'Non' }}</span></h4>
            <h4 class="font-weight-bold">
              Assistance
              <span class="data-orange">{{ rentalData.assistance ? 'Oui' : 'Non' }}</span></h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="6"
            class="mb-2"
          >
            <h4 class="font-weight-bold">
              Etat de la tête:
            </h4>
            {{ rentalData.headState }}
          </b-col>
          <b-col
            sm="6"
            class="mb-2"
          >
            <h4 class="font-weight-bold">
              Etat du largueur:
            </h4>
            {{ rentalData.largState }}
          </b-col>
          <b-col
            cols="12"
            class="text-center"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="ml-1"
              @click="generateContrat"
            >
              Générer un contrat
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
        <b-row
          v-if="rentalData.contract_send === null"
        >
          <b-col cols="12">
            <b-form-file
              v-model="contractSendTemp"
              accept=".jpg, .png, .pdf"
              placeholder="Choisir le contrat"
              drop-placeholder="Déplacer le contrat ici..."
              @input="uploadContractSend(contractSendTemp, rentalData.id, 'contract_send')"
            />
          </b-col>
        </b-row>
        <b-row
          v-else
        >
          <b-col
            v-for="file, indexFile in filesRental"
            :key="indexFile"
            cols="12"
            class="d-flex"
          >
            <b-link
              v-if="file.id === rentalData.contract_send"
              @click="createAndDownloadBlobFile(file.content, file.originalname, file.extension)"
            >
              <h4 class="mb-0 mt-1">
                Contrat : <span class="data-orange">{{ file.originalname }}</span>
              </h4>
            </b-link>
            <b-button
              v-if="file.id === rentalData.contract_send"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              v-b-tooltip.hover
              title="Supprimer"
              variant="flat-danger"
              class="btn-icon rounded-circle"
              @click="removeFile(file.id, 'contract_send')"
            >
              <feather-icon
                icon="DeleteIcon"
                size="20"
              />
            </b-button>
          </b-col>
        </b-row>
        <b-row
          class="w-100 text-center"
        >
          <b-spinner
            v-if="loadingFile"
            class="mt-5 mb-5"
          />
        </b-row>
      </b-card>
      <b-card
        v-if="selectedStep === 3"
      >
        <b-row>
          <b-col cols="12">
            <h2 class="ml-2">
              Étape 3 - Envoi/Retour
            </h2>
          </b-col>
          <b-col cols="6">
            <h4>
              Envoyé le :
            </h4>
            <p class="data-orange">
              {{ rentalData.sendDate | displayDate }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
        <b-row
          v-if="rentalData.delivery_note === null"
        >
          <b-col cols="12">
            <b-form-file
              v-model="deliveryNoteTemp"
              accept=".jpg, .png, .pdf"
              placeholder="Choisir le bordereau d'envoi"
              drop-placeholder="Déplacer le bordereau d'envoi ici..."
              @input="uploadContractSend(deliveryNoteTemp, rentalData.id, 'delivery_note')"
            />
          </b-col>
        </b-row>
        <b-row
          v-else
        >
          <b-col
            v-for="file, indexFile in filesRental"
            :key="indexFile"
            cols="12"
            class="d-flex"
          >
            <b-link
              v-if="file.id === rentalData.delivery_note"
              @click="createAndDownloadBlobFile(file.content, file.originalname, file.extension)"
            >
              <h4 class="mb-0 mt-1">
                Bordereau d'envoi : <span class="data-orange">{{ file.originalname }}</span>
              </h4>
            </b-link>
            <b-button
              v-if="file.id === rentalData.delivery_note"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              v-b-tooltip.hover
              title="Supprimer"
              variant="flat-danger"
              class="btn-icon rounded-circle"
              @click="removeFile(file.id, 'delivery_note')"
            >
              <feather-icon
                icon="DeleteIcon"
                size="20"
              />
            </b-button>
          </b-col>
        </b-row>
        <b-row
          class="w-100 text-center"
        >
          <b-spinner
            v-if="loadingFile"
            class="mt-5 mb-5"
          />
        </b-row>
      </b-card>
      <b-card
        v-if="selectedStep === 4"
      >
        <b-row>
          <b-col cols="12">
            <h2 class="ml-2">
              Étape 4 - Fin de location
            </h2>
          </b-col>
          <b-col cols="6">
            <h4>
              Reçu le :
            </h4>
            <p class="data-orange">
              {{ rentalData.backDate | displayDate }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
        <b-row
          v-if="rentalData.return_note === null"
        >
          <b-col cols="12">
            <b-form-file
              v-model="returnNoteTemp"
              accept=".jpg, .png, .pdf"
              placeholder="Choisir le bordereau de retour"
              drop-placeholder="Déplacer le bordereau de retour ici..."
              @input="uploadContractSend(returnNoteTemp, rentalData.id, 'return_note')"
            />
          </b-col>
        </b-row>
        <b-row
          v-else
        >
          <b-col
            v-for="file, indexFile in filesRental"
            :key="indexFile"
            cols="12"
            class="d-flex"
          >
            <b-link
              v-if="file.id === rentalData.return_note"
              @click="createAndDownloadBlobFile(file.content, file.originalname, file.extension)"
            >
              <h4 class="mb-0 mt-1">
                Bordereau de retour : <span class="data-orange">{{ file.originalname }}</span>
              </h4>
            </b-link>
            <b-button
              v-if="file.id === rentalData.return_note"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              v-b-tooltip.hover
              title="Supprimer"
              variant="flat-danger"
              class="btn-icon rounded-circle"
              @click="removeFile(file.id, 'return_note')"
            >
              <feather-icon
                icon="DeleteIcon"
                size="20"
              />
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
        <b-row
          v-if="rentalData.contract_back === null"
        >
          <b-col cols="12">
            <b-form-file
              v-model="contractBackTemp"
              accept=".jpg, .png, .pdf"
              placeholder="Choisir le contrat de retour"
              drop-placeholder="Déplacer le contrat de retour ici..."
              @input="uploadContractSend(contractBackTemp, rentalData.id, 'contract_back')"
            />
          </b-col>
        </b-row>
        <b-row
          v-else
        >
          <b-col
            v-for="file, indexFile in filesRental"
            :key="indexFile"
            cols="12"
            class="d-flex"
          >
            <b-link
              v-if="file.id === rentalData.contract_back"
              @click="createAndDownloadBlobFile(file.content, file.originalname, file.extension)"
            >
              <h4 class="mb-0 mt-1">
                Contrat de retour : <span class="data-orange">{{ file.originalname }}</span>
              </h4>
            </b-link>
            <b-button
              v-if="file.id === rentalData.contract_back"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              v-b-tooltip.hover
              title="Supprimer"
              variant="flat-danger"
              class="btn-icon rounded-circle"
              @click="removeFile(file.id, 'contract_back')"
            >
              <feather-icon
                icon="DeleteIcon"
                size="20"
              />
            </b-button>
          </b-col>
        </b-row>
        <b-row
          class="w-100 text-center"
        >
          <b-spinner
            v-if="loadingFile"
            class="mt-5 mb-5"
          />
        </b-row>
      </b-card>
    </b-col>
    <vue-html2pdf
      v-if="rentalData && rentalData.id"
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="true"
      :preview-modal="true"
      :enable-download="false"
      :pdf-quality="2"
      :manual-pagination="true"
      :filename="`contrat-location-${rentalData.id}`"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
    >
      <section slot="pdf-content">
        <!-- PDF Content Here -->
        <contract
          :rental-data="rentalData"
        />
      </section>
    </vue-html2pdf>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BProgress,
  BProgressBar, BBadge, BLink, BFormFile,
  VBTooltip, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import VueHtml2pdf from 'vue-html2pdf'
import Contract from './Contract.vue'
/* eslint no-underscore-dangle: 0 */
/* eslint no-param-reassign: 0 */

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BProgress,
    BProgressBar,
    BBadge,
    Contract,
    VueHtml2pdf,
    BLink,
    BFormFile,
    BSpinner,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      rentalData: {},
      rentalState: null,
      value: 0,
      max: 4,
      selectedStep: 1,
      filesRental: [],
      // htmltopdf
      htmlOptions: {
      },
      // files
      contractSendTemp: null,
      deliveryNoteTemp: null,
      returnNoteTemp: null,
      contractBackTemp: null,
      loadingFile: false,
    }
  },
  computed: {
    filename() {
      return `contrat-location-${this.rentalData.id}`
    },
  },
  mounted() {
    const today = new Date()
    store.dispatch('rental/findRental', this.$router.currentRoute.params.id)
      .then(rental => {
        this.rentalData = rental
        // hydrate state
        if (Date.parse(this.rentalData.endDate) < Date.parse(today.toString())) {
          this.rentalState = 'Terminé'
        } else if (Date.parse(this.rentalData.startDate) > Date.parse(today.toString())) {
          this.rentalState = 'A venir'
        } else {
          this.rentalState = 'En cours'
        }
        // hydrate client data
        if (store.state.user.usersList) {
          const [users] = store.state.user.usersList.filter(user => user.id === this.rentalData.user_id)
          // eslint-disable-next-line no-param-reassign
          this.rentalData.user_id = users
        }
        // progressBar
        if (this.rentalData.device.name && this.rentalData.startDate && this.rentalData.endDate && this.rentalData.personInCharge && this.rentalData.user_id) {
          this.value = 1
        }
        if (this.value === 1 && this.rentalData.price && this.rentalData.fallCounter && this.rentalData.headState && this.rentalData.largState && this.rentalData.contract_send) {
          this.value = 2
        }
        if (this.value === 2 && this.rentalData.sendDate && this.rentalData.delivery_note) {
          this.value = 3
        }
        if (this.value === 3 && this.rentalData.backDate && this.rentalData.return_note && this.rentalData.contract_back) {
          this.value = 4
        }
        // rentals files
        store.dispatch('file/fetchFile', { rentalId: rental.id })
          .then(files => {
            if (files.length > 0) {
              files.forEach(file => {
                const projectImage = Buffer.from(file.content).toString('base64')
                file.content = projectImage
                this.filesRental.push(file)
              })
            }
          })
      })
  },
  methods: {
    changeSelectedStep(step) {
      this.selectedStep = step
    },
    async createAndDownloadBlobFile(body, filename, extension = 'png') {
      const result = await this.base64ToArrayBuffer(body)
      const blob = new Blob([result])
      const fileName = `${filename}.${extension}`
      const link = document.createElement('a')
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', fileName)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    base64ToArrayBuffer(base64) {
      const binaryString = window.atob(base64)
      const bytes = new Uint8Array(binaryString.length)
      return bytes.map((byte, i) => binaryString.charCodeAt(i))
    },
    resolveStatutVariant(data) {
      if (data === 'Terminé') return 'success'
      if (data === 'A venir') return 'info'
      if (data === 'En cours') return 'warning'
      return 'dark'
    },
    generateContrat() {
      this.$refs.html2Pdf.generatePdf()
    },
    uploadContractSend(fileTemp, rentalid, emplacement) {
      this.loadingFile = true
      const data = new FormData()
      data.append('file', fileTemp)
      data.append('title', fileTemp.name)
      data.append('name', fileTemp.name)
      data.append('extension', fileTemp.type)
      data.append('mimetype', fileTemp.type)
      data.append('original_name', fileTemp.name)
      data.append('rentalId', rentalid)
      store.dispatch('file/addFile', data)
        .then(file => {
          this.filesRental.push(file.data[0])
          const newData = {}
          if (emplacement === 'contract_send') {
            newData.contract_send = file.data[0].id
          } else if (emplacement === 'delivery_note') {
            newData.delivery_note = file.data[0].id
          } else if (emplacement === 'return_note') {
            newData.return_note = file.data[0].id
          } else if (emplacement === 'contract_back') {
            newData.contract_back = file.data[0].id
          }
          store.dispatch('rental/updateRental', { rentalId: rentalid, body: newData })
            .then(rentalUpdated => {
              this.rentalData = rentalUpdated
              this.loadingFile = false
              this.contractSendTemp = null
              this.deliveryNoteTemp = null
              this.returnNoteTemp = null
              this.contractBackTemp = null
              if (this.value === 1 && this.rentalData.price && this.rentalData.fallCounter && this.rentalData.headState && this.rentalData.largState && this.rentalData.contract_send) {
                this.value = 2
              }
              if (this.value === 2 && this.rentalData.sendDate && this.rentalData.delivery_note) {
                this.value = 3
              }
              if (this.value === 3 && this.rentalData.backDate && this.rentalData.return_note && this.rentalData.contract_back) {
                this.value = 4
              }
            })
            .catch(err => {
              console.log('err', err)
              this.loadingFile = false
              this.contractSendTemp = null
              this.deliveryNoteTemp = null
              this.returnNoteTemp = null
              this.contractBackTemp = null
            })
        })
        .catch(err => {
          console.log('err', err)
          this.loadingFile = false
          this.contractSendTemp = null
          this.deliveryNoteTemp = null
          this.returnNoteTemp = null
        })
    },
    removeFile(fileId, emplacement) {
      this.$swal({
        title: 'Êtes-vous sûr de vouloir supprimer ?',
        text: 'Cette action est irréversible!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui, supprimer!',
        cancelButtonText: 'Annuler',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('file/deleteFile', fileId)
            .then(() => {
              const newData = {}
              if (emplacement === 'contract_send') {
                newData.contract_send = null
              } else if (emplacement === 'delivery_note') {
                newData.delivery_note = null
              } else if (emplacement === 'return_note') {
                newData.return_note = null
              } else if (emplacement === 'contract_back') {
                newData.contract_back = null
              }
              store.dispatch('rental/updateRental', { rentalId: this.rentalData.id, body: newData })
                .then(() => {
                  this.$router.go()
                })
            })
        }
      })
    },
  },
}
</script>

<style scoped>
.prog-bar-ref {
  position: relative;
}
.prog-bar-1 {
  position: absolute;
  top: 38px;
}
.prog-bar-2 {
  position: absolute;
  top:0;
}
.prog-bar-color {
  background-color: white;
}
.data-orange {
  font-weight: 600;
  color: #ff9100;
}
</style>
